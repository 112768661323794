import { List, Map } from 'immutable';
import { memo } from 'react';

import ListTable from 'core/modules/list/components/table';
import { Sorting } from 'core/modules/list/components/table/header/cell';

import ReportingTableRow from './row';

type Props = {
  isSticky?: boolean;
  columnMap?: Map<string, any>;
  columnList?: List<any>;
  reportMap?: Map<string, any>;
  reportList?: List<any>;
  sorting?: Sorting;
  selectedMap?: Map<string, any>;
  reportAggregation: any;
  onSort?: null | ((referential, order) => void);
  onSelectReport: (id: string, selected: boolean) => void;
  onClickCell: (data: any) => void;
  isRetailer: boolean;
};

const ReportingTable = memo((props: Props) => {
  const {
    columnList = List(),
    columnMap,
    reportMap = Map(),
    reportList = List(),
    selectedMap = Map(),
    sorting,
    reportAggregation = Map(),
    onSort,
    onSelectReport,
    onClickCell,
    isRetailer,
    isSticky,
  } = props;

  const getRefId = (ref) => ref.get('key');

  const getRefLabel = (ref) => ref.get('label');

  const getRefNull = () => null;

  const getRefSort = () => true;

  const getSortingId = (_sorting) => _sorting.get('key');

  const getSortingAsc = (_sorting) => _sorting.get('asc');

  return (
    <ListTable
      referentials={columnList}
      columnMap={columnMap}
      getReferentialId={getRefId}
      getReferentialSort={getRefSort}
      getReferentialWidth={getRefNull}
      getReferentialLabel={getRefLabel}
      // Selection disabled for now
      // cellsOnLeft={1}
      cellsOnRight={1}
      sorting={sorting}
      onSort={onSort ? onSort : undefined}
      getSortingId={getSortingId}
      getSortingAsc={getSortingAsc}
      isSticky={isSticky}
      withHeaderMultiLines
    >
      {!reportAggregation.isEmpty() && (
        <ReportingTableRow
          key="reporting-table-row-aggregation"
          reportId="aggregation"
          report={reportAggregation}
          referentials={columnList}
          onClickCell={onClickCell}
          isRetailer={isRetailer}
          isSticky={isSticky}
          isAggregation
        />
      )}
      {reportList.map((reportId) => (
        <ReportingTableRow
          key={`reporting-table-row-${reportId}`}
          reportId={reportId}
          report={reportMap.get(reportId)}
          referentials={columnList}
          selected={selectedMap.get(reportId)}
          onSelect={onSelectReport}
          onClickCell={onClickCell}
          isRetailer={isRetailer}
          isSticky={isSticky}
        />
      ))}
    </ListTable>
  );
});

export default ReportingTable;
export { ReportingTable };
