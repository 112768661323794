import PropTypes from 'prop-types';

import Image from 'components/ui/image';
import { getPackshotURL } from 'core/api/assets';
import MissingImage from 'images/missing.png';

import './packshot.scss';

const resolveUrl = (src, assets) =>
  src || getPackshotURL(assets) || MissingImage;

const ProductPackshot = ({ size, src, assets }) => (
  <div className="ProductPackshot Picture">
    <Image
      width={size}
      height={size}
      src={resolveUrl(src, assets)}
      fallbackSrc={ProductPackshot.defaultSrc}
    />
  </div>
);

ProductPackshot.propTypes = {
  assets: PropTypes.object,
  src: PropTypes.string,
  size: PropTypes.string,
};

ProductPackshot.defaultProps = {
  assets: {},
  src: '',
  size: '64',
};

export default ProductPackshot;
